import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const CaseStudy = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "government-and-councils-image-1" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "government-and-councils-image-1" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "government-and-councils-image-1" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div> 
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Reward Programs | Gift Cards
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Case Study - City of Melbourne
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Australia's largest council cashback program drives Melbourne's recovery.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        WAIVPAY successfully delivers the city’s $40 million dining initiative to
        bring shoppers back to Melbourne’s hospitality sector. Highly successful,
        shoppers received over $8.0M in cashback incentives when they dined in the
        city’s restaurants, cafes and bars.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        The success of the above resulted in City of Melbourne running a further two campaigns both managed by Waivpay.
      </p>
      <Stats/>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);


const Stat = ({ children }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">
        {children[0]}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-primary">
        {children[1]}
      </dd>
    </div>
  </div>
);

const Stats = () => (
  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
    <Stat>
      <span>Total Rebates</span>
      <span>270,154</span>
    </Stat>

    <Stat>
      <span>Total Cashbacks</span>
      <span>$8.03M</span>
    </Stat>

    <Stat>
      <span>Average Cashback</span>
      <span>$29.74</span>
    </Stat>
  </dl>
);

export default CaseStudy;
